export default {
  common: {
    colon: ': ',
    cancel: 'キャンセル',
    confirm: '確認',
    backToTickets: 'チケット一覧に戻る',
    backToTicketDetails: 'チケット詳細に戻る',
    day: ' 日',
    hour: '時間',
    min: '分',
    second: '秒'
  },
  views: {
    voucher: {
      bookingId: '予約番号',
      bookingDetails: '予約詳細',
      printable: '印刷用バウチャー',
      participation: '集合・引換場所の説明',
      inclusion: '含まれるもの',
      restrictions: '参加制限',
      requiredAttire: '参加時必要な服装・持ち物',
      requirements: '参加前、参加時必要事項',
      other: '備考・その他',
      localContact: '現地連絡先',
      cancellation: 'キャンセルポリシー',
      clickUrl: 'チケットを使用するにはこのリンクをクリックしてください'
    },
    common: {
      colon: '：',
      submit: '送信',
      resend: '再送信',
      cancel: 'キャンセル',
      confirm: '確認',
      tickets: 'チケット一覧',
      copyBtn: 'URLをコピー',
      recommendBrowserTips:
        '非推奨のブラウザでアクセスしています。推奨のブラウザを利用しアクセスしてください。'
    },
    booking: {
      details: '予約詳細',
      bookingId: 'Booking {{id}}',
      history: '利用履歴',
      bookedDate: '予約日',
      participationLocations: '参加場所',
      participationLocationKind: {
        MAP_OF_MEETING_POINT: '場所説明',
        NOTE_OF_LOCATION_AND_DIRECTION: '場所、行き方の補足説明',
        LANDMARKS_TO_MEETING_POINT: '集合場所までのランドマーク',
        PICK_UP_CAR: '迎えに行く車',
        PICK_UP_GUIDE: '迎えに行くガイド',
        ITINERARY: 'スケジュール',
        PARKING: '駐車場について'
      },
      ticketType: {
        CODE_CPM: 'QRコードをかざす',
        CODE_MPM: 'QRコードを読み取る',
        SELF_REDEMPTION: 'チケット表示',
        SIMPLE_VOUCHER: 'チケット表示'
      },
      inclusion: '含まれるもの',
      restrictions: '参加制限',
      requirements: '参加前・参加時必要事項',
      attireItems: '参加時必要な服装・持ち物',
      otherInfo: '備考・その他',
      cancellationPolicy: 'キャンセルポリシー'
    },
    otp: {
      title: 'ワンタイムパスワード',
      tips1:
        'お客様のアクセス環境が変わりました。 \n キュリティ保護のため、ワンタイムパスワードによる認証を行います。',
      tips2: 'ワンタイムパスワードを受け取るメールアドレスを登録してください。',
      inputOTPCode:
        'メールに記載されているワンタイムパスワードを入力してください。',
      sendCode:
        '{{email}} にワンタイムパスワードを送付しました。メールを確認してください。',
      invalidEmail: '無効なメールアドレスです。',
      inputEmail: 'メールアドレスを入力してください。',
      inputPassword: 'ワンタイムパスワードを入力してください。',
      sendOTP: 'ワンタイムパスワードを送信する'
    },
    ticket: {
      status: {
        INVALID: '無効',
        AWAITING: '利用可能期間前',
        UNUSED: '利用前',
        USABLE: '利用可能',
        IN_USE: '利用中',
        USED: '利用済み',
        EXPIRED: '期限切れ',
        DISTRIBUTED: '同行者に分配済み',
        RETURNED: '同行者への分配を返却済み'
      },
      subStatus: {
        ENTERED: '入場済み',
        EXITED: '出場済み',
        INITIAL_STATION_CANCELLED: '乗車駅キャンセル'
      },
      nowTime: '現在時刻',
      startTime: '開始時間',
      QRCodeExpirationRemainingTime: 'QRコード有効期限残り',
      screenShotTips: 'スクリーンショットでのご利用は不可です。',
      showScreenToStaff: 'この画面をスタッフにご提示ください。',
      instruction: '利用説明',
      usedAt: '利用日',
      availablePeriod: '利用可能期間',
      bookingId: '予約番号',
      startUsing: '利用開始',
      startUsingTips: 'チケットの利用を開始しますか？',
      startUsingWarn: '*利用開始と同時に有効期間が開始となります。',
      distributeTicket: '分配',
      distributeTicketTips:
        '分配ボタンよりリンクをコピーできない場合、上記のURLをコピーして同行者へ分配してください',
      reDistributeTicket: '再分配する',
      reDistributeTips:
        '※同行者がチケット『未使用』の場合のみ再分配はできます。',
      reDistributeTips2: '※再分配後、分配したリンクが無効になります。',
      copyLinkSuccess: 'リンクをコピーしました。',
      use: '利用',
      confirmUse: '利用内容の確認',
      useTicket: '<span>{{count}}</span>枚を利用します',
      issueTicketTips: '係員の前で利用してください',
      issueTicketNotes: '※利用後は取消不可となります',
      undo: '取り消し',
      redeem: '利用開始',
      thanks: 'ご利用ありがとうございます',
      second: '{{count}}秒',
      expirationDate: '有効期限',
      supplier: '催行会社',
      participatorName: '参加者名',
      validPeriod: '利用後有効期間',
      fixedTime: '{{day}}日間(最終日は現地時刻{{time}}まで)',
      enableCamera: 'カメラ機能を有効にしてください。',
      qrTip: 'QRコードを枠内に入れると自動スキャンされます',
      manualVerify: '手動検証',
      unit: '{{unit}} 第 {{index}} 位',
      afterUse: '利用後'
    },
    confirmUse: {
      title: 'スタッフの前で操作してください！',
      usedTitle: 'チケット使用完了！',
      tips: 'スタッフがいない場合、自分でスライドして使用すると入場できません。',
      usedTips: '利用後は取消不可となります。',
      lockedText: 'スタッフの前で使用',
      unlockText: '使用完了',
      returnText: '戻る',
      closeText: '閉じる'
    },
    history: {
      title: '利用履歴'
    },
    apiErrors: {
      baseError: 'エラーが発生しました。',
      clientError: 'クライアントからのリクエストに誤りがありました。',
      serverError: 'サーバがリクエストの処理に失敗しました。',
      connectionError:
        '接続エラーが発生しました。通信環境をご確認して、再度お試しください。',
      requestError: 'リクエストの作成に誤りがありました。'
    }
  }
};
