export default {
  common: {
    colon: ': ',
    cancel: '취소',
    confirm: '확인',
    backToTickets: '티켓 내역으로 돌아가기',
    backToTicketDetails: '티켓 상세 정보로 돌아가기',
    day: 'D',
    hour: 'H',
    min: 'MIN',
    second: 'SEC'
  },
  views: {
    voucher: {
      bookingId: '예약 번호',
      bookingDetails: '예약 상세',
      printable: '인쇄용 바우처',
      participation: '이용안내',
      inclusion: '포함내용',
      restrictions: '참가제한',
      requiredAttire: '투어 참가/바우처 교환시 지참물 및 복장',
      requirements: '필수 사항',
      other: '추가 정보',
      localContact: '현지연락처',
      cancellation: '취소정책',
      clickUrl: '티켓을 사용하려면 이 링크를 클릭하세요.'
    },
    booking: {
      details: '예약 상세',
      bookingId: 'Booking {{id}}',
      history: '이용 이력',
      bookedDate: '예약일',
      participationLocations: '참여 장소',
      participationLocationKind: {
        MAP_OF_MEETING_POINT: '장소 설명',
        NOTE_OF_LOCATION_AND_DIRECTION: '위치 및 이동 방법 설명',
        LANDMARKS_TO_MEETING_POINT: '체크인 장소의 근처 명소',
        PICK_UP_CAR: '픽업 차량',
        PICK_UP_GUIDE: '픽업 담당 가이드',
        ITINERARY: '일정',
        PARKING: '주차장'
      },
      ticketType: {
        CODE_CPM: 'QR 코드를 잡다',
        CODE_MPM: 'QR 코드 읽기',
        SELF_REDEMPTION: '티켓 표시',
        SIMPLE_VOUCHER: '티켓 표시'
      },
      inclusion: '포함내용',
      restrictions: '참여 제한',
      requirements: '참여 전 · 참가시 필요 사항',
      attireItems: '투어 참가/바우처 교환시 필요 준비물',
      otherInfo: '비고 * 기타',
      cancellationPolicy: '취소 정책'
    },
    otp: {
      title: '일회용 비밀번호',
      tips1:
        '고객님의 접속 환경이 변경되었습니다. \n 정보 보안을 위해 일회용 비밀번호를 이용하여 인증을 진행합니다.',
      tips2: '일회용 비밀번호를 받을 이메일을 등록해주세요.',
      inputOTPCode: '일회용 비밀번호를 입력해주세요.',
      sendCode:
        '{{email}} 로 일회용 비밀번호를 보냈습니다. 메일을 확인하여 주십시오.',
      invalidEmail: '유효하지 않은 메일주소 입니다.',
      inputEmail: '메일주소를 입력해주세요.',
      inputPassword: '원타임 패스워드를 입력해주세요.',
      sendOTP: '원타임 패스워드 전송하기'
    },
    ticket: {
      status: {
        INVALID: '무효',
        AWAITING: '이용 가능 기간 전',
        UNUSED: '이용 전',
        USABLE: '이용 가능',
        IN_USE: '이용 중',
        USED: '이용 완료',
        EXPIRED: '기간 만료',
        DISTRIBUTED: '동행자 분배 완료',
        RETURNED: '동행자 분배 반환 완료'
      },
      subStatus: {
        ENTERED: '입장 완료',
        EXITED: '퇴장 완료',
        INITIAL_STATION_CANCELLED: '입장 취소'
      },
      nowTime: '현재 시간',
      startTime: '시작 시간',
      QRCodeExpirationRemainingTime: 'QR코드의 남은 유효 기간/시간',
      screenShotTips: 'QR 코드 스크린샷으로는 입장할 수 없습니다.',
      showScreenToStaff: '본 화면을 현지 스태프에게 제시하여 주시기 바랍니다.',
      instruction: '이용 설명',
      usedAt: '이용일',
      availablePeriod: '이용 가능 기간',
      bookingId: '예약 번호',
      startUsing: '이용 시작',
      startUsingTips: '티켓 사용을 시작하시겠습니까?',
      startUsingWarn: '*이용 시작과 동시에 유효 기간이 시작됩니다.',
      distributeTicket: '분배하기',
      distributeTicketTips:
        '분배 버튼으로 링크가 복사되지 않을 경우, 아래 링크를 복사하여 동반 참가자에게 전달해 주시기 바랍니다.',
      reDistributeTicket: '재분배하기',
      reDistributeTips: '동행자 티켓이 미사용인 경우에만 재분배가 가능합니다.',
      reDistributeTips2: '*재분배 후, 분배에 이용한 링크는 무효가 됩니다. ',
      copyLinkSuccess: '링크를 복사했습니다.',
      use: '이용',
      confirmUse: '이용 내용 확인',
      useTicket: '<span>{{count}}</span>장 이용합니다',
      issueTicketTips: '현지 스태프 앞에서 이용 시작해 주시기 바랍니다',
      issueTicketNotes: '*이용 시작 후, 취소는 불가합니다',
      undo: '취소',
      redeem: '이용 시작',
      thanks: '이용해주셔서 감사합니다',
      second: '{{count}}초',
      expirationDate: '유효 기간',
      supplier: '공급자',
      participatorName: '참가자 이름',
      validPeriod: '사용 후 유효성',
      fixedTime: '{{day}} 일간 (마지막 날의{{time}}까지 현지시간)',
      enableCamera: '카메라 기능을 활성화하여 주시기 바랍니다. ',
      qrTip: 'QR 코드를 상자 안에 넣으면 자동으로 스캔됩니다.',
      manualVerify: '수동 확인',
      unit: '{{unit}} {{index}} 번째',
      afterUse: '사용 후'
    },
    confirmUse: {
      title: '스태프의 앞에서 작업해주세요!',
      usedTitle: '티켓 이용 완료!',
      tips: '스태프가 없는 경우 사용할 수 없습니다.',
      usedTips: '티켓을 다시 사용할 수 없습니다. 즐거운 여행 되세요!',
      lockedText: '스태프의 앞에서 사용',
      unlockText: '사용 완료',
      returnText: '돌아가기',
      closeText: '닫기'
    },
    history: {
      title: '이용 이력'
    },
    apiErrors: {
      baseError: '에러가 발생했습니다.',
      clientError: '요청에 오류가 발생했습니다.',
      serverError: '서버 요청에 실패하였습니다.',
      connectionError:
        '접속 에러가 발생했습니다. 통신 환경을 다시 한 번 확인하여 주시기 바랍니다.',
      requestError: '요청에 오류가 발생했습니다.'
    }
  }
};
